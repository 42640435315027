// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import handleAllowNotification from './service/notificationPermission';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { getApp, getApps, initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase-messaging-sw';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Service Worker 등록
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log(
          'Service Worker가 scope에 등록되었습니다.:',
          registration.scope,
        );
      })
      .catch(function (err) {
        console.log('Service Worker 등록 실패:', err);
      });
  });
}

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const messaging = getMessaging();

//토큰값 얻기
Notification.requestPermission().then((permission) => {
  if (permission === 'granted') {
    console.log('Notification permission granted.');

    // 토큰 요청
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log('FCM Token:', currentToken);
          // 서버에 토큰 저장 로직 추가 가능
        } else {
          console.log(
            'No registration token available. Request permission to generate one.',
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  } else if (permission === 'denied') {
    console.log('Notification permission denied.');
    // 권한 거부 시 사용자에게 알림 설정 안내 등 추가 조치 가능
  }
});

//포그라운드 메시지 수신
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});
