// public/firebase-messaging-sw.js
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getMessaging } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';

export const firebaseConfig = {
  apiKey: 'AIzaSyCn5U5WrzaYEXj-6xhqSO9tq2CtDoCkY6I',
  authDomain: 'bililge.firebaseapp.com',
  projectId: 'bililge',
  storageBucket: 'bililge.appspot.com',
  messagingSenderId: '715007972500',
  appId: '1:715007972500:web:97037c580ddc57427f5081',
  measurementId: 'G-PQQEBLPBM0',
};
